<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 


			<div class="row">

				<div class="col-md-12 mt-3">


					<div>
						
						<div>
						<h3>Block Dates</h3>
					</div>


					<div class="shadow rounded p-3">					
					
					<form @submit.prevent="change_settings()">
						
						<div class="row">

							<div class="col-md-4">
								<label>Select apartment</label>
								<select class="form-select" v-model="id">
									<option v-for="a in apartments" :value="a.id"> {{a.name}} </option>
								</select>
							</div>

							<div class="col-md-4">
								<label>From</label>
								<input type="date" v-model="from" class="form-control">
							</div>

							<div class="col-md-4">
								<label>To</label>
								<input type="date" v-model="to" class="form-control">
							</div>


							<div class="col-md-12 mt-3">
								
								<button class="btn btn-1" :disabled="disabled"> {{text}} </button>
							</div>

						</div>

					</form>

					</div>




					<div class="mt-3">
						<h3>Blocked Dates</h3>
					</div>



					<div class="shadow rounded p-3 mt-3">
						
					<div class="table-responsive">

						<table class="table">

							<thead>
								<tr>
									<th> Apartment </th> <th> From date </th> <th>To date</th> <th>Action</th>
								</tr>
							</thead>

							<tbody>
								
								<tr v-for="o in orders">
									<td> {{o.apartment_name}} </td>
									<td> {{o.from_date}} </td>
									<td> {{o.to_date}} </td>
									<td> <button @click="delete_date(o.id)" class="btn text-danger"> <i class="bi bi-x"></i> Delete </button> </td>
								</tr>

							</tbody>
							
						</table>
						
					</div>

					</div>




					</div>
					

				</div>


				
			</div>


		

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from "axios"
	import Swal from 'sweetalert2'
	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
			apartments : [],
			id : '',
			text : 'Save changes',
			disabled : false,
			orders : [],
			from : '',
			to : ''	
			
			}
		},

		methods : {

			async delete_date(id){
				const res = await axios.post(this.$store.state.url+'api/delete-blocked-date',{
					id : id 
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.blocked_dates() 
			},


			async blocked_dates(){
				const res = await axios.get(this.$store.state.url+'api/blocked-dates').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.orders = res 
			},

			async get_apartments(){
				const res = await axios.get(this.$store.state.url+'api/all-listings').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.apartments = res 
			},

			async change_settings(){
				if(!this.id){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Apartment is required!",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}


				if(!this.from){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "From date  is required!",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}

				if(!this.to){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "To date is required!",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}

				this.text = 'Please wait...'
				this.disabled = true


				const res = await axios.post(this.$store.state.url+'api/block-by-date',{
					id : this.id,
					from : this.from,
					to : this.to 
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				Swal.fire({
					  position: "top-center",
					  icon: "success",
					  title: "Changes saved successfully",
					  showConfirmButton: false,
					  timer: 1500
					});

			this.text = 'Save changes'
			this.disabled = false 
			this.id = ''
			this.from = ''
			this.to = ''
			this.blocked_dates()

				
			}
		
		},

		created(){
			this.get_apartments()
			this.blocked_dates()
		}

	}

</script>