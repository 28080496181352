<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 


			<div class="row">

				<div class="col-md-12 mt-3">


					<div>
						
						<div>
						<h3>Mark Up Prices</h3>
					</div>


					<div class="shadow rounded p-3">					
					
					<form @submit.prevent="new_markup()">
						
						<div class="row">

							<div class="col-md-4">
								<label>Amount(Ksh.)</label>
								<input type="text" v-model="amount" class="form-control">
							</div>

							<div class="col-md-4">
								<label>Rate(%)</label>
								<input type="text" v-model="rate" class="form-control">
							</div>


							<div class="col-md-12 mt-3">
								
								<button class="btn btn-1" :disabled="disabled"> {{text}} </button>
							</div>

						</div>

					</form>

					</div>




					<div class="mt-3">
						<h3>Blocked Dates</h3>
					</div>



					<div class="shadow rounded p-3 mt-3">
						
					<div class="table-responsive">

						<table class="table">

							<thead>
								<tr>
									<th> Amount </th> <th> Rate </th>  <th>Action</th>
								</tr>
							</thead>

							<tbody>
								
								<tr v-for="m in markups">
									<td> Ksh.{{m.amount}} </td>
									<td> {{m.rate}}% </td>
									<td> <button @click="delete_rate(m.id)" class="btn text-danger"> <i class="bi bi-x"></i> Delete </button> </td>
								</tr>

							</tbody>
							
						</table>
						
					</div>

					</div>




					</div>
					

				</div>


				
			</div>


		

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from "axios"
	import Swal from 'sweetalert2'
	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
			text : 'Submit',
			disabled : false,
			amount : '',
			rate : '',
			markups : []	
			
			}
		},

		methods : {

			async delete_rate(id){
				const res = await axios.post(this.$store.state.url+'api/delete-markup',{
					id : id 
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.get_markups()
			},


			async get_markups(){
				const res = await axios.get(this.$store.state.url+'api/get-markups').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.markups = res 
			},

	

			async new_markup(){
				if(!this.amount){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Amount is required!",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}


				if(!this.rate){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Rate  is required!",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}

				

				this.text = 'Please wait...'
				this.disabled = true


				const res = await axios.post(this.$store.state.url+'api/create-markup',{
					amount : this.amount,
					rate : this.rate 
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				Swal.fire({
					  position: "top-center",
					  icon: "success",
					  title: "Rate added successfully",
					  showConfirmButton: false,
					  timer: 1500
					});

			this.text = 'Submit'
			this.disabled = false 
			this.amount = ''
			this.rate = ''
			

				
			}
		
		},

		created(){
			this.get_markups()
		}

	}

</script>