<template>


	<!-- for header part -->
	<header> 

		<div class="logosec"> 
			<img src="/assets/images/new-logo.png" class="logo"> 
			<button class="btn btn-lg menuicn color-1" id="menuicn" @click="header()"> <i class="bi bi-grid-fill"></i> </button> 
		</div> 

		<div class="searchbar"> 
			<router-link style="margin-right: 10px;" to="/all-apartments" class="btn btn-2"> <i class="bi bi-layers"></i> Apartments</router-link>
			<router-link style="margin-right: 10px;" to="/orders" class="btn btn-2"> <i class="bi bi-collection"></i> Orders</router-link>
			<router-link to="/profile" class="btn btn-2"> <i class="bi bi-gear"></i> Profile</router-link>

		</div> 

		<div class="message"> 
			<div class="circle"></div>
			<div class="dp"> 
		 	<router-link style="font-size: 25px;" class="btn btn-lg" to="/orders"><i class="bi bi-chat-dots color-1"></i></router-link>
			</div> 
			<router-link class="btn btn-lg" to="/dashboard"><i class="bi bi-person"></i>  </router-link>
			 
		</div> 

	</header> 

	


</template>

<script>
	
	export default{
		name : 'header',
		methods : {
			header(){
		let menuicn = document.querySelector(".menuicn"); 
let nav = document.querySelector(".navcontainer"); 
nav.classList.toggle("navclose"); 


			}
		},

		created(){
			
		}
	}

</script>