<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 


			<div class="row">

				<div class="col-md-12 mt-3">


					<div>
						
						<div>
						<h3>Agents</h3>
					</div>


					<div class="shadow rounded p-3">					
					
					<div class="table-responsive">
						<table class="table">

							<thead>
								<tr>
									<th> Agent name </th>
									<th> Email </th>
									<th> Phone </th>
									<th> County </th>
									<th> Address </th>
									<th> Action </th>
								</tr>
							</thead>

							<tbody>
								
								
							<tr v-for="a in agents">
								
								<td> <router-link class="btn p-0 text-success" :to=" { name : 'agent-apartments', params : { id : a.id } } "> {{a.first_name}} {{a.last_name}} </router-link>  </td>
								<td> {{a.email}} </td>
								<td> {{a.phone}} </td>
								<td> {{a.county}} </td>
								<td> {{a.address}} </td>
								<td> <button @click="delete_agent(a.id)" class="btn text-danger"> <i class="bi bi-x"></i> Delete </button> </td>

							</tr>
								
								

							</tbody>
							
						</table>
						
					</div>


					</div>

					</div>
					

				</div>


				
			</div>


		

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from "axios"
	import Swal from 'sweetalert2'
	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
			agents : []	
			
			}
		},

		methods : {

			async delete_agent(id){
				const res = await axios.post(this.$store.state.url+'api/delete-agent',{
					id : id 
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.get_agents() 
			},

			async get_agents(){
				const res = await axios.get(this.$store.state.url+'api/list-agents').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.agents = res 
			}
		
		},

		created(){
		this.get_agents()	
		}

	}

</script>