<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 


			<div class="row">

				<div class="col-md-12 mt-3">


					<div>
						
						<div>
						<h3>Profile</h3>
					</div>


					<div class="shadow rounded p-3">					
					
					<form @submit.prevent="change_settings()">
						
						<div class="row">
							<div class="col-md-4">
								<label>Username</label>
								<input disabled type="text" v-model="username" class="form-control">
							</div>

							<div class="col-md-4">
								<label>Password</label>
								<input type="password" v-model="password" class="form-control">
							</div>


							<div class="col-md-12 mt-3">
								
								<button class="btn btn-1" :disabled="disabled"> {{text}} </button>
							</div>

						</div>

					</form>

					</div>

					</div>
					

				</div>


				
			</div>


		

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from "axios"
	import Swal from 'sweetalert2'
	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
			username : this.$store.state.username,
			password : '',
			text : 'Save changes',
			disabled : false	
			
			}
		},

		methods : {
			async change_settings(){
				if(!this.password){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Password is required!",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}

				this.text = 'Please wait...'
				this.disabled = true


				const res = await axios.post(this.$store.state.url+'api/update-admin-password',{
					password : this.password
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				Swal.fire({
					  position: "top-center",
					  icon: "success",
					  title: "Changes saved succfully",
					  showConfirmButton: false,
					  timer: 1500
					});

			this.text = 'Save changes'
			this.disabled = false 

				
			}
		
		}

	}

</script>