import { createRouter, createWebHistory } from 'vue-router'
import Login from '../components/Login'
import Dashboard from '../components/Dashboard'
import AllApartments from '../components/AllApartments'
import Profile from '../components/Profile'
import Orders from '../components/Orders'
import ApartmentDetails from '../components/ApartmentDetails'
import OrderDetails from '../components/OrderDetails'
import BlockDates from '../components/BlockDates'
import MarkupPrices from '../components/MarkupPrices'
import Discounts from '../components/Discounts'
import Agents from '../components/Agents'
import AgentApartments from '../components/AgentApartments'



const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },


    {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard
  },



    {
    path: '/all-apartments',
    name: 'all-apartments',
    component: AllApartments
  },

  {
    path: '/profile',
    name: 'profile',
    component: Profile
  },

  {
    path: '/orders',
    name: 'orders',
    component: Orders
  },



  {
    path: '/apartment-details/:id',
    name: 'apartment-details',
    component: ApartmentDetails
  },


  {
    path: '/order-details/:id',
    name: 'order-details',
    component: OrderDetails
  },


    {
    path: '/block-dates',
    name: 'block-dates',
    component: BlockDates
  },



    {
    path: '/mark-up-prices',
    name: 'mark-up-prices',
    component: MarkupPrices
  },


    {
    path: '/discounts',
    name: 'discounts',
    component: Discounts
  },


    {
    path: '/agents',
    name: 'agents',
    component: Agents
  },


    {
    path: '/agent-apartments/:id',
    name: 'agent-apartments',
    component: AgentApartments
  },




 ]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
