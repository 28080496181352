<template>
	<div class="navcontainer"> 
			<nav class="nav"> 
				<div class="nav-upper-options"> 
					<div class="nav-option"> 
					<router-link class="btn option1" to="/dashboard"><i class="bi bi-speedometer"></i> Dashboard</router-link> 
					</div> 


		

		<div class="nav-option">
	<div class="dropdown">
  <button class="btn option1 dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
     <i class="bi bi-layers"></i> Apartments
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
   
    <li><router-link to="/all-apartments" class="dropdown-item">View  apartments</router-link></li>

  </ul>
</div>
</div>


	<div class="nav-option"> 
					<router-link class="btn option1" to="/profile"><i class="bi bi-gear"></i> Profile</router-link> 
					</div>



						<div class="nav-option"> 
					<router-link class="btn option1" to="/orders"><i class="bi bi-list"></i> Orders</router-link> 
					</div>





		<div class="nav-option">
	<div class="dropdown">
  <button class="btn option1 dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
     <i class="bi bi-grid"></i> Settings
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
   
    <li><router-link to="/block-dates" class="dropdown-item">Block dates</router-link></li>

       <li><router-link to="/mark-up-prices" class="dropdown-item">Mark up prices</router-link></li>

         <li><router-link to="/discounts" class="dropdown-item">Discounts</router-link></li>

  </ul>
</div>
</div>



	<div class="nav-option">
	<div class="dropdown">
  <button class="btn option1 dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
     <i class="bi bi-people"></i> Agents
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
   
    <li><router-link to="/agents" class="dropdown-item">View  agents</router-link></li>

  </ul>
</div>
</div>







						<div class="nav-option"> 
					<button @click="logout()" class="btn option1"><i class="bi bi-power"></i> Log Out</button> 
					</div> 


					
				</div> 
			</nav> 
		</div>
</template>


<script>
	export default{

		name : 'sidebar',

		methods : {
			logout(){
				this.$router.push('/')
			}
		}

	}

</script>