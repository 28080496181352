<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 


			<div class="row">

				<div class="col-md-12 mt-3">


					<div>
						
						<div>
						<h3>Apartments</h3>
					</div>


					<div class="shadow rounded p-3">					
					
					<div class="table-responsive">

						<table class="table">

							<thead>
								<tr>
									<th> Apartment name </th>
									<th> Price </th>
									<th> County </th>
									<th> Address </th>
									<th> Action </th>
								</tr>
							</thead>


							<tbody>
								
								<tr v-for="p in apartments">
									<td>  <router-link class="btn p-0 text-success" :to=" { name : 'apartment-details', params : {id : p.id} } " > {{ p.name }}</router-link> </td>
									<td> Ksh.{{ p.agent_price }} </td>
									<td> {{ p.county }} </td>
									<td> {{ p.address }} </td>
									<td> <router-link :to=" { name : 'apartment-details', params : {id : p.id} } " class="btn text-success"> <i class="bi bi-three-dots"></i> </router-link> </td>
								</tr>

							</tbody>
							
						</table>
						
					</div>


					</div>

					</div>
					

				</div>


				
			</div>


		

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from "axios"
	import Swal from 'sweetalert2'
	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
			apartments : []	
			
			}
		},

		methods : {
			async get_apartments(){
				const res = await axios.get(this.$store.state.url+'api/all-listings').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.apartments = res 
			}
		
		},

		created(){
		this.get_apartments()	
		}

	}

</script>