<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 


			<div class="row">

				<div class="col-md-12 mt-3">


					<div>
						
						<div>
						<h3 class="text-danger">Order#{{order_number}}</h3>
					</div>

					<div class="shadow rounded p-3">

					<div class="row">
						<div class="col-md-6 col-6">
							<div>
						<h5>Customer Details</h5>
					</div>
						</div>

						<div class="col-md-6 col-6" style="text-align:right;">
							<div class="dropdown">
  <button class="btn btn-1 dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
    Actions
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">

  	 <li><button @click="mark_checked_in()" class="dropdown-item text-success"> <i class="bi bi-arrow-down"></i> Check in </button></li>

  	  <li><button @click="mark_checked_out()" class="dropdown-item text-primary"> <i class="bi bi-arrow-up"></i> Check out </button></li>
   
    <li><button @click="delete_order()" class="dropdown-item text-danger"> <i class="bi bi-x"></i> Delete order </button></li>
  </ul>
</div>
						</div>

					</div>					
					
					<div class="table-responsive">
						<table class="table">

							<thead>
								<tr>
									<th> Customer </th>
									<th> Email </th>
									<th> Phone </th>
									<th> ID Number </th>
								</tr>
							</thead>

							<tbody>
							<tr>
								<td> {{ customer_name }} </td>
								<td> {{ email }} </td>
								<td> {{ phone }} </td>
								<td> {{ id_number }} </td>
							</tr>
						

							</tbody>


							<tbody>
								
								

							</tbody>
							
						</table>
					</div>









					<div>
						<h5>Booking Details</h5>
					</div>					
					
					<div class="table-responsive">
						<table class="table">

							<thead>
								<tr>
									<th> Order# </th>
									<th> Apartment </th>
									<th> Check In Date </th>
									<th> Check Out Date </th>
									<th> Checked In </th>
									<th> Check Out </th>
								</tr>
							</thead>

							<tbody>
							<tr>
								<td> {{ order_number }} </td>
								<td> {{ apartment_name }} </td>
								<td> {{ check_in_date }} </td>
								<td> {{ check_out_date }} </td>
								<td> {{ checked_in }} </td>
								<td> {{ checked_out }} </td>
							</tr>
						

							</tbody>


							<tbody>
								
								

							</tbody>
							
						</table>
					</div>



					<div>
						<h5>Others</h5>
					</div>



							<div class="table-responsive">
						<table class="table">

							<thead>
								<tr>
									<th> Adults </th>
									<th> Kids </th>
									<th> Pets </th>
									
								</tr>
							</thead>

							<tbody>
							<tr>
								<td> {{ adults }} </td>
								<td> {{ kids }} </td>
								<td> {{ pets }} </td>
							
							</tr>
						

							</tbody>


							<tbody>
								
								

							</tbody>
							
						</table>
					</div>






					<div>
						<h5>Total Cost Ksh. {{total_cost}} </h5>
					</div>










					</div>

					</div>
					

				</div>


				
			</div>


		

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from "axios"
	import Swal from 'sweetalert2'
	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
			id : '',
			details : [],
			order_number : '',
			customer_name : '',
			email : '',
			phone : '',
			apartment_name : '',
			id_number : '',
			check_in_date : '',
			check_out_date : '',
			checked_in : '',
			checked_out : '',
			adults : '',
			kids : '',
			pets : ''	
			
			}
		},

		methods : {

			async mark_checked_out(){
				const res = await axios.post(this.$store.state.url+'api/mark-checked-out',{
					id : this.id 
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				Swal.fire({
					  position: "top-center",
					  icon: "success",
					  title: "Client checked out",
					  showConfirmButton: false,
					  timer: 1500
					});				 
			},

			async mark_checked_in(){
				const res = await axios.post(this.$store.state.url+'api/mark-checked-in',{
					id : this.id 
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				Swal.fire({
					  position: "top-center",
					  icon: "success",
					  title: "Client checked in",
					  showConfirmButton: false,
					  timer: 1500
					});			 
			},

			async delete_order(){
				const res = await axios.post(this.$store.state.url+'api/delete-order',{
					id : this.id 
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.$router.push('/orders')				 
			},


			async get_details(){
				const res = await axios.get(this.$store.state.url+'api/order-details/'+this.id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.details = res 
				res.forEach((data)=>{
					this.order_number = data.order_number
					this.customer_name = data.first_name+' '+data.last_name
					this.email = data.email
					this.phone = data.phone
					this.apartment_name = data.apartment_name
					this.id_number = data.id_number
					this.total_cost = data.total_cost
					this.check_in_date = data.check_in_date
					this.check_out_date = data.check_out_date
					this.checked_in = data.checked_in
					this.checked_out  = data.checked_out
					this.adults = data.adults
					this.kids = data.kids
					this.pets = data.pets
				})
				 
			}
		
		},

		created(){
			this.id = this.$route.params.id
			this.get_details()	
		}

	}

</script>