<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 


			<div class="row">

				<div class="col-md-12 mt-3">

					<div>
						
						<div>
						<h3> {{name}} </h3>
					</div>


					<div class="shadow rounded p-3">


						<div class="row">
							
							<div class="col-md-8">


					<form @submit.prevent="edit_apartment()" enctype="multipart/form-data">
							<div class="row">
								<div class="col-md-6 mt-3">
									<label>Category</label>
									<select class="form-select" v-model="category">
										<option v-for="c in categories" :value="c.id">{{c.name}}</option>
									</select>
								</div>


								<div class="col-md-6 mt-3">
									<label>Name of apartment</label>
									<input type="text" class="form-control" placeholder="Enter name of apartment" v-model="name">
								</div>


								<div class="col-md-6 mt-3">
									<label>Address</label>
									<input type="text" class="form-control" placeholder="Enter address of apartment" v-model="address">
								</div>

								<div class="col-md-6 mt-3">
									<label>County</label>
									<input type="text" class="form-control" placeholder="Enter county of apartment" v-model="county">
								</div>

								<div class="col-md-6 mt-3">
									<label>Price</label>
									<input type="number" min="1" class="form-control" placeholder="Enter price of apartment" v-model="price">
								</div>


								

								

								<div class="col-md-6 mt-3">
									<label>Rules</label>
									<input type="text" class="form-control" placeholder="Enter house rules" v-model="rules">
								</div>


								<div class="col-md-4 mt-3">
									<label>Kitchens</label>
									<input type="number" min="0" class="form-control" placeholder="Number of kitchens" v-model="kitchens">
								</div>


								<div class="col-md-4 mt-3">
									<label>Bathrooms</label>
									<input type="number" min="0" class="form-control" placeholder="Number of bathrooms" v-model="bathrooms">
								</div>

								<div class="col-md-4 mt-3">
									<label>Bedrooms</label>
									<input type="number" min="0" class="form-control" placeholder="Number of bedrooms" v-model="bedrooms">
								</div>

								<div class="col-md-12 mt-3">
									<label>Feature image</label>
									<input type="file" class="form-control" @change="get_image">
								</div>


								<div class="col-md-6 mt-3">
									<button class="btn btn-1" :disabled="disabled">{{text}}</button>
								</div>


							</div>

						</form>



						<div class="border-top mt-3"></div>
								
								<div class="mt-3">
									
									<h6>Gallery</h6>


									<div class="row mt-3">

										<div class="col-md-4" v-for="g in gallery">

											<div :style=" { backgroundImage : 'url('+this.$store.state.url+'assets/gallery/'+g.image+')', 'minHeight' : '200px', 'width' : '100%', 'backgroundPosition' : 'center', 'backgroundSize' : 'cover'  }" class="rounded m-3"></div>
											
										</div>

				
										
									</div>


								</div>


								
							</div>



							<div class="col-md-4">

								<div>
									<h6>Feature image</h6>
									<p v-if="!image">Loading image...</p>
									<div :style=" { backgroundImage : 'url('+this.$store.state.url+'assets/apartment-images/'+image+')', 'minHeight' : '200px', 'width' : '100%', 'backgroundPosition' : 'center', 'backgroundSize' : 'cover'  }" class="rounded"></div>


									<div class="mt-3 border p-2 rounded">
										<h6>Amenities</h6>

										<div style="height:300px; overflow: scroll;">


									<table class="table">
									<tr> <th> Name </th> <th>Cost</th> </tr>

									<tbody>
										<tr v-for="a in amenities">
											<td> {{ a.name }} </td>
											<td> Ksh.{{a.cost}} </td>
											<td> <button @click="delete_amenity(a.id)" class="btn text-danger"> <i class="bi bi-x"></i> </button> </td>
										</tr>
									</tbody>
										
									</table>

											
										</div>


									<h6 class="mt-2">Add new</h6>

									<form @submit.prevent="new_amenity()">
										<input type="text" placeholder="Amenity name" class="form-control" v-model="amenity_name">
										<input type="text" placeholder="Cost" class="form-control mt-2" v-model="amenity_cost">
										<button class="btn btn-1 mt-3" :disabled="disabled3"> {{text3}} <i class="bi bi-plus"></i> </button>
									</form>


									</div>

									<div style="padding-top: 50px;">
										<button @click="delete_apartment()" class="btn btn-danger">Delete this property </button>

									</div>

								</div>
								
							</div>



					


						</div>

			
				
					</div>

					</div>
					

				</div>


				
			</div>


		

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from "axios"
	import Swal from 'sweetalert2'
	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
			details : [],
			id : '',
			name : '',
			category : '',
			county : '',
			address : '',
			price : '',
			rules : '',
			kitchens :'',
			bedrooms : '',
			bathrooms : '',
			text : 'Save changes',
			disabled : false,
			image : '',
			gallery : [],
			categories : [],
			image2 : '',
			form_data : new FormData(),
			amenities : [],
			amenity_name : '',
			amenity_cost : '',
			text3 : 'Add',
			disabled3 : false
			}
		},

		methods : {

			async  new_amenity(){
				if(!this.amenity_name  || !this.amenity_cost ){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Amenity name or cost cannot be empty",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}

				this.text3 = 'Please wait...'
				this.disabled3 = true

				const res = await axios.post(this.$store.state.url+'api/new-amenity',{
					apartment  : this.id,
					name : this.amenity_name,
					cost : this.amenity_cost
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.get_amenities()	
				this.text3 = 'Add'
				this.disabled3 = false
				 
			},

			async  delete_amenity(id){
				const res = await axios.post(this.$store.state.url+'api/delete-amenity',{
					id  : id 
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.get_amenities()		
				 
			},

			async  get_amenities(){
				const res = await axios.get(this.$store.state.url+'api/get-amenities/'+this.id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.amenities = res		
				 
			},

				get_image(event){
				this.image2 = event.target.files[0]
				
			},


					async edit_apartment(){

				if(!this.category){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Category is required",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}



				if(!this.address){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Address is required",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}



				if(!this.kitchens){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Kitchens is required",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}

				if(!this.bathrooms){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Bathrooms is required",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}


				if(!this.bedrooms){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Bedrooms is required",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}

				if(!this.county){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "County is required",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}

				if(!this.price){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Price is required",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}



					 const config = {
      header : {
        'Content-Type' : 'multipart/form-data'
      }
    }

    		
    		this.form_data.append('category',this.category)
    		this.form_data.append('name',this.name)
    		this.form_data.append('address',this.address)
    		this.form_data.append('county',this.county)
    		this.form_data.append('price',this.price)
    		this.form_data.append('description',this.rules)
    		this.form_data.append('kitchens',this.kitchens)
    		this.form_data.append('bathrooms',this.bathrooms)
    		this.form_data.append('bedrooms',this.bedrooms)
    		this.form_data.append('id',this.id)
    		this.form_data.append('image',this.image2)

    		this.text = 'Please wait...'
    		this.disabled = true
    		
    		

				const res = await axios.post(this.$store.state.url+'api/edit-apartment',
					this.form_data
				,config).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				

				if(res=='success'){
					Swal.fire({
					  position: "top-center",
					  icon: "success",
					  title: "Changes saved!",
					  showConfirmButton: false,
					  timer: 1500
					});
				}else{

					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Something went wrong!",
					  showConfirmButton: false,
					  timer: 1500
					});

				}
				this.get_details()
			this.text = 'Save changes'
    		this.disabled = false 
				
			},

			async get_categories(){
				const res = await axios.get(this.$store.state.url+'api/list-categories').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.categories = res 
			},

			async  delete_apartment(){
				const res = await axios.post(this.$store.state.url+'api/delete-apartment',{
					id : this.id 
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.$router.push('/my-apartments')		
				 
			},

			async  apartment_gallery(){
				const res = await axios.get(this.$store.state.url+'api/apartment-gallery/'+this.id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.gallery = res		
				 
			},

			async get_details(){
				const res = await axios.get(this.$store.state.url+'api/apartment-details/'+this.id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.details = res

				res.forEach((data)=>{
					this.name = data.name 
					this.category = data.category
					this.county = data.county
					this.address = data.address
					this.price = data.price
					this.rules = data.description
					this.bathrooms = data.bathrooms
					this.bedrooms = data.bedrooms
					this.kitchens = data.kitchens
					this.image = data.image

				}) 
		
				 
			}
		
		},

		created(){
		this.id = this.$route.params.id 
		this.get_details()	
		this.apartment_gallery()
		this.get_categories()
		this.get_amenities()
		}

	}

</script>