<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 


			<div class="row">

				<div class="col-md-12 mt-3">


					<div>
						
						<div>
						<h3>Discounts</h3>
					</div>


					<div class="shadow rounded p-3">					
					
					<form @submit.prevent="new_discount()">
						
						<div class="row">

							<div class="col-md-4">
								<label>Days</label>
								<input type="text" v-model="days" class="form-control">
							</div>

							<div class="col-md-4">
								<label>Discount(%)</label>
								<input type="text" v-model="discount" class="form-control">
							</div>


							<div class="col-md-12 mt-3">
								
								<button class="btn btn-1" :disabled="disabled"> {{text}} </button>
							</div>

						</div>

					</form>

					</div>




					<div class="mt-3">
						<h3>Discounts</h3>
					</div>



					<div class="shadow rounded p-3 mt-3">
						
					<div class="table-responsive">

						<table class="table">

							<thead>
								<tr>
									<th> Days </th> <th> Discount </th>  <th>Action</th>
								</tr>
							</thead>

							<tbody>
								
								<tr v-for="d in discounts">
									<td> {{d.days}} </td>
									<td> {{d.discount}}% </td>
									<td> <button @click="delete_discount(d.id)" class="btn text-danger"> <i class="bi bi-x"></i> Delete </button> </td>
								</tr>

							</tbody>
							
						</table>
						
					</div>

					</div>




					</div>
					

				</div>


				
			</div>


		

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from "axios"
	import Swal from 'sweetalert2'
	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
			text : 'Submit',
			disabled : false,
			days : '',
			discount : '',
			discounts : []	
			
			}
		},

		methods : {

			async delete_discount(id){
				const res = await axios.post(this.$store.state.url+'api/delete-discount',{
					id : id 
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.get_discounts()
			},


			async get_discounts(){
				const res = await axios.get(this.$store.state.url+'api/get-discounts').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.discounts = res 
			},

	

			async new_discount(){
				if(!this.days){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Days is required!",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}


				if(!this.discount){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Rate  is required!",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}

				

				this.text = 'Please wait...'
				this.disabled = true


				const res = await axios.post(this.$store.state.url+'api/create-discount',{
					days : this.days,
					discount : this.discount 
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				Swal.fire({
					  position: "top-center",
					  icon: "success",
					  title: "Discount added successfully",
					  showConfirmButton: false,
					  timer: 1500
					});

			this.text = 'Submit'
			this.disabled = false 
			this.days = ''
			this.discount = ''

			this.get_discounts()
			

				
			}
		
		},

		created(){
			this.get_discounts()
		}

	}

</script>