<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 


			<div class="row">

				<div class="col-md-12 mt-3">


					<div>
						
						<div>
						<h3>Orders</h3>
					</div>


					<div class="shadow rounded p-3">					
					
					<div class="table-responsive">
						<table class="table">

							<thead>
								<tr>
									<th> Order# </th>
									<th> Apartment </th>
									<th> Customer </th>
									<th> Email </th>
									<th> Phone </th>
									<th> Action </th>
								</tr>
							</thead>

							<tbody>
								
								<tr v-for="o in orders">
									<td> {{o.order_number}} </td>
									<td> {{o.apartment_name}} </td>
									<td> {{o.first_name}} {{o.last_name}} </td>
									<td> {{o.email}} </td>
									<td> {{o.phone}} </td>
									<td> <router-link :to=" { name : 'order-details', params : { id : o.id } } " class="btn text-success"> <i class="bi bi-three-dots"></i> </router-link> </td>
								</tr>

							</tbody>


							<tbody>
								
								

							</tbody>
							
						</table>
						
					</div>


					</div>

					</div>
					

				</div>


				
			</div>


		

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from "axios"
	import Swal from 'sweetalert2'
	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
			orders : []	
			
			}
		},

		methods : {
			async get_orders(){
				const res = await axios.get(this.$store.state.url+'api/get-orders').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.orders = res 
			}
		
		},

		created(){
		this.get_orders()	
		}

	}

</script>